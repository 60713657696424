import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { Delete, Pool } from "@material-ui/icons";

import CustomModal from "../../Components/CustomModal";
import SimpleInput from "../../Components/SimpleInput";
import SimpleDate from "../../Components/SimpleDate";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import SimpleAutocomplete from "../../Components/SimpleAutocomplete";

import { defaultPoll, getTeams, searchSchools } from "./utils";
import { formatDate } from "../../utils/dates";
import { savePoll, updatePoll } from "../../crud/api/polls.crud";
import * as pollActions from "../../../redux/ducks/poll.duck";
import PercentageTable from "./PercentageTable";

const FormDialog = ({ mode, setDialog, school }) => {
  const { selected_school, user, selectedPoll } = useSelector((store) => ({
    selected_school: store.school.selected_school,
    user: store.auth.user,
    selectedPoll: store.poll.poll,
  }));
  const dispatch = useDispatch();
  const [poll, setPoll] = useState(defaultPoll);
  const [errors, setErrors] = useState(defaultPoll);
  const [loading, setLoading] = useState(false);
  const [msgError, setmsgError] = useState("");

  const [tableOptions, setTableOptions] = useState([]);
  const [selectedTableTeam, setSelectedTableTeam] = useState({
    id: 0,
    name: user.is_admin ? "All organizations" : "All teams",
    options: [],
  });

  const [tableData, setTableData] = useState([]);
  const open = mode === "editing" || mode === "create";
  const title = mode === "editing" ? "Edit Check-in" : "New Check-in";

  //For teams or schools
  const [staffTeams, setStaffTeams] = useState([]);
  const [adminSchools, setAdminSchools] = useState([]);

  const addOneDay = (dateString) => {
    //console.log(dateString);
    const date = new Date(dateString);

    date.setDate(date.getDate() + 2);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (mode === "editing") {
      const formatedEndDate = new Date(
        addOneDay(
          selectedPoll.poll.end_date
            ? selectedPoll.poll.end_date.split("T")[0]
            : new Date().toISOString().split("T")[0]
        )
      );
      const formatedCloseDate = new Date(
        addOneDay(
          selectedPoll.poll.close_date
            ? selectedPoll.poll.close_date.split("T")[0]
            : new Date().toISOString().split("T")[0]
        )
      );
      const formatedSelectedPoll = {
        id: selectedPoll.id,
        name: selectedPoll.poll.name,
        close_date: formatedCloseDate,
        end_date: formatedEndDate,
        question_text: selectedPoll.poll.question_text,
        options: selectedPoll.options,
        all_schools: selectedPoll.all_teams,
        schools: user.is_admin
          ? selectedPoll.schools
          : selectedPoll.teams.map((t) => {
              return {
                name: t.name,
                id: t.id,
              };
            }),
      };
      setPoll(formatedSelectedPoll);
    }
  }, [mode, selectedPoll]);

  const handleClose = () => {
    setDialog("close");
    setmsgError("");
    setPoll({ ...defaultPoll, options: [{ value: "" }] });
  };

  const handleChange = ({ target }, index) => {
    if (target.name.includes("option")) {
      let updatedOptions = poll.options;
      updatedOptions[index].value = target.value;
      setPoll({ ...poll, options: updatedOptions });
    } else setPoll({ ...poll, [target.name]: target.value });
  };

  const handleBlur = ({ target }) =>
    setErrors({ ...errors, [target.name]: !target.value && "Required field" });

  const removeOption = (index) => {
    const newOptions = poll.options?.filter((a, idx) => idx !== index);
    setPoll({ ...poll, options: newOptions });
  };

  const addOption = () => {
    const newOptions = [...poll.options, { value: "", disabled: false }];
    setPoll({ ...poll, options: newOptions });
  };

  const create = () => {
    let options = poll.options.filter((option) => option.value != "");
    setmsgError("");

    if (options.length > 0) {
      setmsgError("");
      if (poll.end_date < poll.close_date) {
        setmsgError("The end date cannot be less than the start date");
        return;
      }
      setLoading(true);
      const newPoll = {
        poll: {
          name: poll.name,
          close_date: formatDate("yyyy-MM-dd", poll.close_date),
          end_date: formatDate("yyyy-MM-dd", poll.end_date),
          question_text: poll.question_text,
        },
        all_schools: poll.all_schools,
        options: poll.options,
        schools: user.is_admin
          ? poll.all_schools
            ? []
            : poll.schools.map(({ id }) => ({ id }))
          : [],
        teams_id: user.is_admin
          ? null
          : poll.all_schools
          ? []
          : poll.schools.map(({ id }) => ({ id })),
        slug: selected_school.slug,
      };
      if (
        newPoll.poll.close_date === undefined ||
        newPoll.poll.end_date === undefined
      ) {
        setmsgError("Error when creating Poll");
        setLoading(false);
        return;
      }
      /*      console.log("Poll to create", newPoll); */
      savePoll(newPoll)
        .then((res) => {
          dispatch(pollActions.actions.updatePolls(res));
          handleClose();
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    } else {
      setmsgError("An option needs to be added.");
      return;
    }
  };

  const update = () => {
    if (poll.end_date < poll.close_date) {
      setmsgError("The end date cannot be less than the start date");
      return;
    }
    setLoading(true);
    const newPoll = {
      id: poll.id,
      poll: {
        id: poll.id,
        name: poll.name,
        close_date: formatDate("yyyy-MM-dd", poll.close_date),
        question_text: poll.question_text,
        end_date: formatDate("yyyy-MM-dd", poll.end_date),
      },
      all_schools: poll.all_schools,
      options: poll.options.map(({ id, value }) => ({ id, value })),
      schools: user.is_admin
        ? poll.all_schools
          ? []
          : poll.schools.map(({ id }) => ({ id }))
        : [],
      teams_id: user.is_admin
        ? null
        : poll.all_schools
        ? []
        : poll.schools.map(({ id }) => ({ id })),
      slug: selected_school.slug,
    };
    /*   console.log("----Edit----");
    console.log(newPoll); */
    updatePoll(newPoll, newPoll.id)
      .then((res) => {
        dispatch(pollActions.actions.updatePolls(res));
        handleClose();
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const formatDataForTable = (data) => {
    const dataToFormat = data ? data : poll.options;
    const formatedData = dataToFormat.map((op) => {
      return {
        name: op.value || "",
        percentage: op.answer_percentage_str || "",
      };
    });
    return formatedData;
  };

  const getTableTeams = async () => {
    if (selectedPoll.options_per_teams || selectedPoll.options_per_school) {
      if (user.is_admin) {
        const fullArr = [
          { id: 0, name: "All organizations", options: [] },
          ...selectedPoll.options_per_school,
        ];
        setTableOptions(fullArr);
      } else {
        const _staffTeams = await getTeams(school.slug);
        const repeatedTeams = selectedPoll.options_per_team.filter((team1) =>
          _staffTeams.some((team2) => team2.id === team1.id)
        );
        const fullArr = [
          { id: 0, name: "All Teams", options: [] },
          ...repeatedTeams,
        ];
        setTableOptions(fullArr);
      }
    }
  };

  const handleTableDataChange = (e) => {
    const selectedTeamId = e.target.value.id;
    const newTableData = tableOptions.filter(
      (op) => op.id === selectedTeamId
    )[0];
    const newOptions = formatDataForTable(newTableData.options);
    setSelectedTableTeam(newTableData);
    if (selectedTeamId === 0) {
      setTableData(formatDataForTable());
    } else {
      setTableData(newOptions);
    }
  };

  const getSchoolsOrTeams = async () => {
    if (user.is_admin) {
      const schools = await searchSchools();
      const formatedSchools = schools.map((school) => {
        return {
          id: school.id,
          name: school.name,
        };
      });
      setAdminSchools(formatedSchools);
    } else {
      const teams = await getTeams(school.slug);
      const formatedTeams = teams.map((team) => {
        return {
          id: team.id,
          name: team.name,
        };
      });
      setStaffTeams(formatedTeams);
    }
  };

  useEffect(() => {
    getSchoolsOrTeams();
    setTableData(formatDataForTable());
    getTableTeams();
  }, [poll, selectedPoll]);

  const disabledButton = useMemo(() => {
    if (
      loading ||
      !poll.name ||
      !selected_school ||
      poll.question_text === "" ||
      poll.options[0].value === "" ||
      (poll.all_schools ? false : poll.schools.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  }, [poll]);

  return (
    <CustomModal
      open={open}
      width="md"
      title={title}
      loader={loading}
      onClose={handleClose}
      onConfirmButton={() => (poll.id ? update() : create())}
      hideConfirmButton={false}
      disabledConfirmButton={disabledButton}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <SimpleInput
            id="poll_name_input_dialog"
            label="Name"
            name="name"
            value={poll?.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleDate
            id="poll_close_date_input_dialog"
            label="Start Date"
            name="close_date"
            value={poll?.close_date}
            onChange={handleChange}
            format="MMMM dd"
            minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleDate
            id="poll_close_date_input_dialog"
            label="End Date"
            name="end_date"
            value={poll?.end_date}
            onChange={handleChange}
            format="MMMM dd"
            minDate={poll?.close_date}
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleInput
            id="poll_question_text_input_dialog"
            label="Question text"
            name="question_text"
            value={poll?.question_text}
            onChange={handleChange}
          />
        </Grid>
        {poll.id != undefined ? (
          <Grid item xs={12}>
            <span style={{ color: "red" }}>
              Existing options may not be modified nor deleted
            </span>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={2} item xs={12} sm={6}>
          {poll.options.map(({ value, disabled }, index) => (
            <Fragment key={index}>
              <Grid item xs={10}>
                <SimpleInput
                  value={value}
                  label={`Option ${index + 1}`}
                  name={`options[${index}].value`}
                  id={`poll_option[${index}]_dialog`}
                  onChange={(e) => handleChange(e, index)}
                  disabled={disabled ? true : false}
                  // disabled={!(user.staff_member_admin || user.is_admin)}
                />
              </Grid>

              {/* <Grid item xs={2} className="d-flex align-items-center">
                <Tooltip title="Remove option" placement="top">
                  <button
                    id="team_delete_team_input_dialog"
                    className="btn btn-icon btn-sm btn-light btn-hover-danger text-danger mx-3"
                    onClick={(e) => removeOption(index)}
                  >
                    <Delete />
                  </button>
                </Tooltip>
              </Grid> */}
            </Fragment>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <Button variant="contained" onClick={addOption}>
            Add option
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SimpleCheckbox
            name="all_schools"
            label={user.is_admin ? "All organizations" : "All teams"}
            onChange={handleChange}
            value={poll.all_schools}
            id="poll_all_schools_input_dialog"
          />
        </Grid>
        <Grid item xs={12}>
          {/* <SimpleAutocomplete
            multiple
            name="schools"
            label={user.is_admin ? "Organizations" : "Teams"}
            value={poll.schools}
            onChange={handleChange}
            disabled={poll.all_schools}
            searchFunction={searchSchools}
            id="poll_schools_input_dialog"
          /> */}
          <SimpleAutocomplete
            multiple
            id="poll_schools_input_dialog"
            name="schools"
            label={user.is_admin ? "Organizations" : "Teams"}
            disabled={poll.all_schools}
            options={user.is_admin ? adminSchools : staffTeams}
            value={poll.schools}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <span id="mensajeError" style={{ color: "red" }}>
        {msgError}
      </span>
      {mode === "editing" && (
        <PercentageTable
          data={tableData}
          options={tableOptions}
          value={selectedTableTeam}
          onChange={handleTableDataChange}
          label={user.is_admin ? "Organizations" : "Teams"}
        />
      )}
    </CustomModal>
  );
};

export default FormDialog;
